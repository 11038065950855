// Tento bundle se dodatečně připojí na stránce typu elections přímo v boxu

import * as featuresElections from './featureCollections/featuresElections';

const importedModules = {
  ...featuresElections,
};
const results = Object.values(importedModules).map((func) => func());

Promise.all(results).then(
  () => {},
  () => {}
);
