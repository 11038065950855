/**
 * Modul electionMap zajistuje inicializaci a ovladani volebnich map
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se ma aplikovat
 */
export const dynamicElectionMapList = async () => {
  const electionMapElement = document.querySelector('div[id^=map]');

  if (electionMapElement) {
    const { configureElectionMap } = await import(
      /* webpackChunkName: 'chunk-election-map-list' */
      '../../box/elections/electionMap'
    );

    configureElectionMap();
  }
};

/**
 * Modul electionBanner zajistuje inicializaci a ovladani volebnich banneru
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se ma aplikovat
 */
export const dynamicElectionBannerList = async () => {
  const electionBannerElements = document.querySelectorAll('div[id^=electionsBanner]');

  if (electionBannerElements.length > 0) {
    const { configureElectionBanner } = await import(
      /* webpackChunkName: 'chunk-election-banner-list' */
      '../../box/elections/electionBanner'
    );

    electionBannerElements.forEach((banner) => {
      configureElectionBanner(window.bannersProps[banner.id]);
    });
  }
};

/**
 * Modul electionSearch zajistuje inicializaci a ovladani volebniho vyhledavani
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se ma aplikovat
 */
export const dynamicElectionSearchList = async () => {
  const electionSearchElements = document.querySelectorAll('.city-search');

  if (electionSearchElements.length > 0) {
    const { configureElectionSearch } = await import(
      /* webpackChunkName: 'chunk-election-search-list' */
      '../../box/elections/electionSearch'
    );

    electionSearchElements.forEach((searchItem) => {
      configureElectionSearch(searchItem as HTMLElement);
    });
  }
};
